.App {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*
  background-image: url(./assets/bg-noise.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  */
}
.section{
  width: 100%;
  min-height: calc(100vh - 76px);
  display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.container{
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.content{
  width: 900px;
  margin: 0 auto;
}
.content.grid {
  display: flex;
  align-items: center;
}
.content.centered p{
  text-align: center;
}
.content.grid > div{
  flex: 0 0 60%;
  width: 60%;
  margin-left: 5%;
}
.content.grid > div p{
  margin-bottom: 20px;
}
.content.grid iframe, .content.grid img {
  flex: 0 0 35%;
  width: 35%;
  height: auto;
}
img.section-icon {
  flex: 0 0 100px;
  width: 100px;
}
.content.grid.gallery{
  margin-top: 60px;
}
.content.grid.gallery > img {
  flex: 0 0 25%;
  width: 25%;
}
div.button{
  border-radius: 20px;
  background-color: var(--pink-accent);
  border: 2px solid var(--blue-accent);
  box-shadow: 5px 5px 0 0 var(--aqua-accent);
  width: 250px;
  padding: 10px 60px;
  margin: 20px auto;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
}
div.button:hover{
  box-shadow: 8px 8px 0 0 var(--aqua-accent);
  background-color: #c72266;
}
div.button a{
  color: var(--text-color);
  text-decoration: none;
  font-size: 24px;
}
.button, button{
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
h2.section-title{
  position: relative;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 2px;
}
p{
  color: var(--text-color);
  text-align: justify;
  font-size: 14px;
}
.content.grid.left p {
  text-align: left;
}
.content.grid.right p {
  text-align: right;
}
div#footer {
  width: 100%;
  height: 60px;
  text-align: center;
}
div#footer .social-icons svg{
  width: 40px;
  height: 40px;
  padding: 5px 20px 0;
}
div#footer .social-icons svg path{
  fill: var(--text-color);
}
@media screen and (min-width: 768px) and (max-width: 900px){
  .content{
    width: 720px;
  }
}
@media screen and (max-width: 768px) {
  h2.section-title{
    font-size: 42px;
    line-height: 48px;
  }
  p{
    text-align: center;
  }
  .section{
    padding: 98px 0;
  }
  .section .container {
    padding: 0 20px;
  }
  .content{
    width: 90%;
  }
  .content.grid {
    gap: 0px;
    flex-direction: column;
  }
  .content.grid.left{
    flex-direction: column-reverse;
  }
  .content.grid.right{
    flex-direction: column;
  }
  .content.grid.left p, .content.grid.right p{
    text-align: center !important;
    font-size: 12px;
  }
  .content.grid img{
    width: 200px;
  }
  .content.grid img.section-icon, .content.grid > div {
    flex: 0 0 100%;
    width: 100%;
    margin-left: 0;
  }
  .content.grid.gallery{
    margin-top: 40px;
  }
  .content.grid.gallery > img {
    flex: 0 0 100%;
    width: 100%;
  }
  div.button {
    padding: 6px 40px;
    width: 200px;
  }
}

@supports (-webkit-touch-callout: none){
  h2.section-title{
    font-size: 42px;
    line-height: 52px;
  }
}