div#hero.section{
    padding-top: 76px;
    /*
    background-image: url(../../assets/bg-lines_1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    */
}
div#hero .container{
    text-align: center;
}
div#hero .container .main-img{
    width: 420px;
    animation: floatNshine 3s infinite ease-in-out forwards;
    background-image: url(../../assets/animation-bg.png);
    background-size: 102%;
    background-position: center center;
    backdrop-filter: blur(0.5px);
    display: block;
    margin: 0 auto;
}
@keyframes floatNshine {
	from {
        transform: translate(0%, 0%);
        -webkit-filter: drop-shadow(0px 0px 10px #ffffff33);
        filter: drop-shadow(0px 0px 10px #ffffff66);
     }
      65% {
        transform: translate(0%, -5%);
        -webkit-filter: drop-shadow(0px 0px 12px #ffffff66);
        filter: drop-shadow(0px 0px 12px #ffffff77);
     }
      to {
        transform: translate(0%, 0%);
        -webkit-filter: drop-shadow(0px 0px 10px #ffffff33);
        filter: drop-shadow(0px 0px 10px #ffffff66);
     }
}
div#hero .container img.hero-logo{
    width: 480px;
}
div#hero .container h1{
    margin-top: 0px;
    color: #ffffff66;
    letter-spacing: 2px;
    line-height: 2em;
}

@media screen and (max-width: 768px) {
    div#hero.section{
        background-image: none;
    }
    div#hero .container .main-img {
        width: 100%;
    }
    div#hero .container img.hero-logo{
        width: 80%;
    }
    div#hero .container h1{
        font-size: 18px;
        margin-top: 10px;
    }
}
@media screen and (min-width: 540px) and (max-width: 768px){
    div#hero .container .main-img, div#hero .container img.hero-logo {
        width: 480px;
    }
}