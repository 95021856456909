div#header {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    padding: 10px 20px;
    background-color: rgb(255 255 255 / 10%);
    z-index: 999;
}
div#header .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
div#header .logo a{
    text-decoration: none;
}
div#header .logo h1 {
    margin: 0;
    color: var(--text-color);
}
div#header .logo img{
    padding-top: 4px;
}
div#header .navbar {
    display: flex;
    align-items: center;
}
div#header .navbar ul li {
    display: inline-block;
    padding: 0 20px;
}
div#header .navbar ul li a{
    color: var(--text-color);
    text-decoration: none;
}
div#header .navbar ul li a.button{
    color: var(--text-color);
    border-radius: 10px;
    background-color: var(--pink-accent);
    border: 1px solid var(--blue-accent);
    box-shadow: 2px 2px 0 0 var(--aqua-accent);
    padding: 6px 22px;
    font-size: 16px;
    cursor: pointer;
}
div#header .navbar ul li a:hover, div#header .navbar ul li a:focus{
    font-weight: bold;
    text-decoration: underline;
}
div#header .navbar ul li a.button:hover{
    background-color: #c72266;
    box-shadow: 3px 3px 0 0 var(--aqua-accent);
    text-decoration: none;
}
div#header .navbar .social-icons{
    margin-left: 20px;
}
div#header .navbar .social-icons svg{
    width: 20px;
    height: 20px;
    padding: 5px 10px 0;
}
div#header .navbar .social-icons svg path{
    fill: var(--text-color);
}
@media screen and (max-width: 768px) {
    div#header{
        padding: 14px 20px;
    }
    div#header .logo img{
        width: 60px;
    }
    div#header .navbar ul{
        list-style-type: none;
        padding-inline-start: 0;
    }
    div#header .navbar ul li{
        display: none;
    }
    div#header .navbar ul li:last-child{
        display: block;
        padding: 0 10px;
    }
    div#header .navbar ul li a.button {
        font-size: 14px;
        padding: 10px 18px;
    }
    div#header .navbar .social-icons {
        margin-left: 0px;
    }
    div#header .navbar .social-icons svg {
        padding: 5px 0 0 16px;
    }
}

@media screen and (min-width: 768px) and (max-width: 900px){
    div#header .navbar ul {
        padding-inline-start: 0;
    }
    div#header .navbar ul li {
        padding: 0 10px;
    }
}